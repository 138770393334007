import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./index.css";
import "./contact.css";
import Nav from "../components/nav"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../components/contact";
import imgHero from "../images/gfx/hero-bus-open-data-package.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-bus-open-data-package.jpg"
import imgLiveTracking from "../images/gfx/school-bus-tracking-system.jpg"
import imgNotification from "../images/gfx/boarding-notification.jpg"
import imgLiveScanning from "../images/gfx/school-bus-tracking-app.jpg"
import imgPassengerScan from "../images/gfx/coach-scan-ticket-side.jpg"
import Footer from "../components/footer";

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="School bus tracking system | School bus tracking app" />
        <Nav activeTab="packages" />
        <div>
            <div>

              <div className="hero-standard" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1>School Bus Tracking System</h1>
                <h2>Real-time data with the LIVE add-on</h2>
                <div className="hero__links">
                  <Link to="/book-demo/" className="btn">Book a demo</Link>
                </div>
              </div>

              <div className="mobile-hero-standard" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1>School Bus Tracking System</h1>
                <h2>Real-time data with the LIVE add-on</h2>
                <div className="hero__links">
                  <Link to="/book-demo/" className="btn">Book a demo</Link>
                </div>
              </div>

              <div className="contact-page">
                <div className="article" style={{ maxWidth: 1200 }}>
                  <div className='article-inner'>

                    <div className="contact__section">
                      <p>Our new LIVE add-on can be used with our <Link to="/simple-package/">Simple</Link> and <Link to="/complete-package/">Complete</Link> Packages giving the additional benefits of live vehicle tracking, boarding notifications and real-time ticket scan data, all within a single low-cost system.</p>

                      <div className="content-two-col">
                        <div>
                          <img src={imgLiveTracking} alt="School bus tracking system" />
                        </div>
                        <div>
                          <h2>Live vehicle tracking</h2>
                          <p>Our new driver app automatically publishes the school bus location data using low-cost, consumer grade tablet devices.</p>
                          <p>Passengers and parents can see the exact location of their school bus in real-time, accessed directly from the mobile ticket on their phone.</p>
                          <p>SMS service alerts, available on our <Link to="/simple-package/">Simple</Link> and <Link to="/complete-package/">Complete</Link> packages, help inform travellers ahead of time. Live vehicle tracking complements this with real-time updates, helping further reduce phone calls to you or the school.</p>
                        </div>
                      </div>

                      <div className="content-two-col">
                        <div>
                          <img src={imgLiveScanning} alt="School bus tracking app" />
                        </div>
                        <div>
                          <h2>Real-time ticket scan data</h2>
                          <p>With the new LIVE add-on, operators automatically receive scan log data in the back office in real-time, so you can keep track of who has boarded and when.</p>
                          <p>The driver app (connected using 4G networks) can update you immediately with any failed and successful ticket scans, enhancing your safeguarding capability.</p>
                          <p>This enhancement can help reassure worried parents if their child made it onto the school bus. When information is time-sensitive there is no substitute for real-time data.</p>
                        </div>
                      </div>

                      <div className="content-two-col">
                        <div>
                          <img src={imgNotification} alt="School bus boarding notification" />
                        </div>
                        <div>
                          <h2>Boarding notifications</h2>
                          <p>When safety is a top priority, parents need to know that it's safe send their child on the bus to school.</p>
                          <p>Boarding notifications with the LIVE add-on offer peace of mind to parents that their child has safely made it onto the school bus.</p>
                          <p>It also helps parents plan when to pick up their child from the bus stop and when they can expect their child to arrive home.</p>
                          <p>The feature is optional for both transport providers and the parents who wish to receive them.</p>
                        </div>
                      </div>

                      <div className="content-two-col">
                        <div>
                          <img src={imgPassengerScan} alt="Passenger scanning ticket" />
                        </div>
                        <div>
                          <h2>Save time and increase revenue</h2>
                          <p>The LIVE add-on extends our Simple and Complete Packages which are designed to save you time and help you increase revenue on your school bus services.</p>
                          <p><Link to="/simple-package/">Simple Package</Link>: Mobile bus passes. Ticket scanning. Reporting. Service Alerts. Waiting lists.</p>
                          <p><Link to="/complete-package/">Complete Package</Link>: All the features of the Simple Package PLUS card and direct debit payments, voucher codes, automated renewals, ad hoc ticket types and more.</p>
                        </div>
                      </div>

                      <div style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto'}}>
                        <h2>More information</h2>
                        <p>The LIVE add-on is available as an upgrade with either our <Link to="/simple-package/">Simple</Link> or <Link to="/complete-package/">Complete</Link> packages. Contact our friendly sales team below for more information and a quote based on your requirements.</p>

                        <form action="/live-addon-info-requested/" name="live-tracking-enquiry" method="POST" className="contact-form" data-netlify="true" data-netlify-honeypot="bot-field">
                          <p style={{ display: 'none' }}>
                            <label>Don't fill this out if you're human: <input name="bot-field" /></label>
                          </p>
                          <div className="contact-form__group">
                            <label>Your name *</label>
                            <input placeholder="Your name *" name="name" required className="contact-form__input" />
                          </div>
                          <div className="contact-form__group">
                            <label>Company *</label>
                            <input placeholder="Company *" name="company" required className="contact-form__input" />
                          </div>
                          <div className="contact-form__group">
                            <label>Email *</label>
                            <input placeholder="Email *" name="email" type="email" required className="contact-form__input" />
                          </div>
                          <div className="contact-form__group">
                            <label>Phone *</label>
                            <input placeholder="Phone *" name="phone" required className="contact-form__input" />
                          </div>
                          <input type="hidden" name="form-name" value="live-tracking-enquiry" />
                          <p>
                            <button className="btn" type="submit">Submit enquiry</button>
                          </p>
                        </form>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage
